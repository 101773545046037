<script>
import { frontendURL } from 'dashboard/helper/URLHelper';

export default {
  props: {
    source: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    accountId: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    dashboardPath() {
      return frontendURL(`accounts/${this.accountId}/dashboard`);
    },
    source2() {
      // return "https://willay.nyc3.cdn.digitaloceanspaces.com/images/layout/isotipo.svg";
      return "https://willay.nyc3.cdn.digitaloceanspaces.com/images/layout/logo-omni-iso.svg";
    },
    name2() {
      return "Willay";
    }
  },
};
</script>

<template>
  <div class="w-8 h-8">
    <router-link :to="dashboardPath" replace>
      <img :src="source2" :alt="name2" />
    </router-link>
  </div>
</template>
